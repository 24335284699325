$breakpoint1: 960px;

:root {
  --gutter: 75px;
  --headroom: 50px;
  --footroom: 30px;
  --fontsize: 18px;
  --fontsize_burger: 24px;
  --margin_left: 115px;
  --img_width: 300px;
  --img_height: 300px;
  --burger_size: 80px;
  --pretty_black: #020202;
}

html::-webkit-scrollbar {
  width: 6px;
}

html::-webkit-scrollbar-track {
  background-color: lightgrey;
}

html::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 0;
}


@media only screen and (max-width: 1024px) {
  :root {
    //--fontsize: 14px;
  }
}
@media only screen and (max-width: 768px) {
  :root {
    --fontsize: 14px;
  }
}

body {
  font-family: "Barlow-Regular","sans-serif";
  font-size: var(--fontsize);
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: "AlfaSlabOne", "sans-serif";
}

.hidden {
  display: none;
}

.centered {
  //margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  //width: 100%;
}
.headroom_spacer {
  height: var(--burger_size);
}

.decorated {
  flex-direction: row;

  .text {
    width: 20em;    
  }
 
  @media only screen and (max-width: $breakpoint1) {
    flex-direction: column;
  }
}

#page_wrapper {
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  background-repeat: no-repeat;
  // background-color: white;
  background-size: cover;
  display: flex;
  flex-direction: column;
  //overflow: hidden visible;
}

#page_container {
  // margin: 0 var(--margin_left);
  // width: calc(100vw - var(--margin_left) * 2);
  max-width: 100vw;
}

#riders {
  position: fixed;
  z-index: 1100;
  left: var(--margin_left);

  height: var(--headroom);
  display: flex;
  align-items: center;
  max-width: 100vw;
  .rider {
    cursor: pointer;
    display: inline;
    text-transform: lowercase;
    font-size: var(--fontsize);
    border-left: solid 2px black;
    padding: 0 0.5em;

    &:nth-of-type(2),
    &:first-of-type {
      border-left: none;
      padding-left: 0;
    }
    
    &.active {
      font-family: "AlfaSlabOne";
      position: relative;
      top: 1px;
    }
  }

  &.burger {
    flex-direction: column;
    align-items: flex-start;
    background-color: black;
    top: var(--burger_size);
    height: 100%;
    max-width: 100vw;
    overflow: hidden;

    .rider {
      border: 0;   
      padding: 0.5em;
      color: white;
      background-color: black;
      margin-top: -1px;
      font-size: var(--fontsize_burger);
    }

    #burger {
      display: block;

      span {
        position: relative;
        display: block;
        top: 35px;
        left: 20px;
        width: 43px;
        height: 4px;
        margin: 0 10px 0 0;
        background: var(--pretty_black);
        transition: background .15s;
        transition-delay: .2s;

        &:before {
          position: absolute;
          top: -11px;
          width: 100%;
          height: 100%;
          content: '';
          background: var(--pretty_black);
          transition: all .35s;
        }
        &:after {
          position: absolute;
          top: 11px;
          width: 100%;
          height: 100%;
          content: '';
          background: var(--pretty_black);
          transition: all .35s;
        }
      }
    }

    /*
    &:before {
      content: "=";
      border: 1px solid black;
      border-radius: 50%;
      width: var(--burger_size);
      height: var(--burger_size);
      position: absolute;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    */

  }

  #burger {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--burger_size);
    height: var(--burger_size);
    display: none;
  }

}

.card {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  //width: 100vw;
  //min-width: 100vw;
}

.container {
  margin: 0 1em;
  height: 95%;
  display: flex;
  flex-direction: column;

  .innerbox {
    width: 80%;
    max-width: 1100px;
    height: 80%;
    margin: auto;
    scrollbar-width: thin;
    scrollbar-color: grey lightgrey;

    &.vertical {
      overflow-y: scroll;
    }

    .article {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1em;
      .image {
        img {
          max-width: 200px;
          max-height: 200px;
        }
      }
      .text {
        margin: 0 1em;
      }
      @media only screen and (max-width: $breakpoint1) {
        flex-direction: column;
        .image {
          margin: 0 auto;
        }
      }
    }
  }
  
  .gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;
    position: relative;

    @media only screen and (max-width: $breakpoint1) {
      &.slideshow {
        display: block;

        .textblock {
          text-align: center;
          .title {
            height: unset !important;
          }
        }
        .slick-prev,
        .slick-next {
          top: 30%;
          width: 40px;
          height: 40px;
        }
        .slick-prev {
          transform: rotate(-90deg);
          left: -5px;
        }
        .slick-next {
          transform: rotate(90deg);
          right: -5px;
        }
      }
    }

    .tile {
      //max-width: 30%;
      margin: 0 1em;

      .icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .icon {
          margin: 1em 0.5em 2em 0;
          img {
            width: 60px;
           }
        }
      }
      .item {
        display: flex;
        flex-direction: column;
        flex-basis: 300px;

        a {
          text-decoration: none;
          color: inherit;
        }

        img {
          // height: max-content;
          align-self: flex-start;
        }

        .textblock {
          display: flex;
          flex-direction: column;
          .title {
            font-family: "AlfaSlabOne";
            font-size: var(--fontsize);
            height: 6em;
          }
          .text {
            font-family: "Barlow-Regular";
            font-size: var(--fontsize);
          }
          &.switch {
            flex-direction: column-reverse;
            .text {
              min-height: unset;
            }
          }
          &.minheight_320 {
            min-height: 320px;
          }
        }
        &.black {
          color: black;
        }
        &.white {
          color: white;
        }
        &.textleft {
          flex-direction: row;
          .textblock {
            margin-right: 1em;
          }
        }
        &.textright {
          flex-direction: row-reverse;
          .textblock {
            margin-left: 1em;
          }
        }
        &.textbottom {
          flex-direction: column-reverse;
        }
        &.text_title_switch {
          .textblock {
            flex-direction: column-reverse;
            .text {
              min-height: unset;
            }
          }
        }
      }
    }
    @media only screen and (max-width: $breakpoint1) {
      flex-direction: column; 
      align-items: center;
      .tile {
        max-width: 75%;
        .item {
          flex-direction: column;
          justify-content: center;
          flex-basis: unset;
          
          img {
            //height: 20vh;
            //width: 20vh;
            justify-self: flex-start;
            max-width: 100% !important;
            max-height: 400px;
          }
          .textblock {
            &.minheight_320 {
              min-height: unset;
            }
            .title {
              height: unset;
            }
            .text {
              min-height: unset;
              ul {
                padding-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  .decorated {
    .decoration {
      margin-right: 1em;
      width: 700px;
      @media only screen and (max-width: $breakpoint1) {
        width: 80vw;
      }
    }
    
  }

  &.mail_form {
    .centered {
      margin-top: 10vh;
      align-items: first baseline;
      flex-direction: row;
      @media only screen and (max-width: $breakpoint1) {
        flex-direction: column;
        margin-top: 0;
      }
    }
    .content {
      width: 300px;
    }
    .form {
      width: 700px;

      .row {
        margin: 5px 0;
        textarea, 
        input[type=submit],
        input[type=text] {
          color: #333333;
          width: 100%;
          @media only screen and (max-width: $breakpoint1) {
            width: 80vw;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint1) {
    .hero {
      width: 80vw;
    }
  }
}

#thanx {
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 1100;

    .box {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 101;
      background-color: white;
      color: black;
      border: 2px solid black;
      border-radius: 5px;
      padding: 1em;

      .content {
        padding: 1em;
      }

      .ok {
        position: relative;
        margin-top: 1em;
        float: right;
        border: 1px solid grey;
        border-radius: 3px;
        padding: 0.5em;
        background-color: lightgrey;
        cursor: pointer;
      }
    }
  }
}

#footer {
  position: fixed;
  //left: var(--margin_left);
  width: 100vw;
  height: var(--footroom);
  z-index: 100;
  bottom: 1.5em;
  //width: 100%;
  text-align: center;
  @media only screen and (max-width: $breakpoint1) {
    display: none;
  }
}

.form {
  display: flex;
  flex-direction: column;

  .row {
    height: 2.5em;

    &.textarea {
      height: auto;
    }
  }
}

.tiles {
  .decoration {
    max-width: var(--img_width);
    margin-right: 2em;
  }
  ul {
    list-style: none;
    max-width: calc(var(--img_width) * 2 + 4em);
    li.tile {
      padding: 1em;
      // max-width: 21em;
      position: relative;
      float: left;
      display: flex;
      flex-direction: column;

      img {
        max-width: var(--img_width);
        width: var(--img_width);
        display: inline;
      }
      .title {
        max-width: var(--img_width);
        font-family: AlfaSlabOne, sans-serif;
        margin-bottom: 0.5em;
      }
      .text {
        margin-top: 0.5em;
        max-width: var(--img_width);
      }
      .footer {
        clear: both;
        max-width: var(--img_width);
        font-size: calc(var(--fontsize) * 2 / 3);
      }
    }
  }

  @media only screen and (max-width: $breakpoint1) {
    flex-direction: column;
    .decoration {
      max-width: 90vw;
      margin: 0;
    }
    ul {
      max-width: 90vw;
      li.tile {
        .title,
        .text,
        .footer,
        img {
          max-width: 90vw;
        }
      }
    }
  }
}

.karte {
  #the_map {
    width: 70%;
    height: 70%;
  }
  #partners {
    color: black;
  }
  @media only screen and (max-width: $breakpoint1) {
    #the_map {
      margin-top: 1em;
      font-size: 10px;
      width: 90%;
    }
    .text {
      width: 90vw;
      margin: 0 auto;
    }
  }
}
